<!--
 * @Author: lbh
 * @Date: 2021-06-24 11:40:16
 * @LastEditors: lbh
 * @LastEditTime: 2021-06-24 15:21:06
 * @Description: 別府下載頁
-->
<template>
  <div class="beppu-box">
    <div
      v-if="showText"
      class="text"
    >無法直接下載別府集團，請選擇在瀏覽器中打開或前往手機應用市場手動下載</div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      showText: false
    }
  },
  mounted () {
    document.title = '別府APP';
    document.querySelector('link').href = '/beppu.jpg';
    try {
      let sUserAgent = navigator.userAgent.toLowerCase();
      let isIphone = sUserAgent.match(/iphone/i) == "iphone";
      let isAndroid = sUserAgent.match(/android/i) == "android";
      if (isIphone) {
        window.location.href = 'https://apps.apple.com/hk/app/%E5%88%A5%E5%BA%9C%E9%9B%86%E5%9C%98/id989774293';
      } else if (isAndroid) {
        window.location.href = 'https://ricepon.oss-cn-hongkong.aliyuncs.com/android-api/beppu.apk';
      } else {
        this.showText = true;
      }
    } catch (error) {
      alert(error.toString())
    }

  },
  methods: {
  }
}
</script>
<style lang="less" scoped>
.text {
  padding: 20px;
}
</style>
